import React, { useEffect, useState } from 'react';
import {  CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import FaceOffTableV2 from './FaceOffTablev2';

const defaultHeadCells = [
  {
    id: 'ranking',
    label: 'Ranking',
    type: 'text', // Default type for numeric/text values
    numeric: true,
  },
  {
    id: 'tier',
    label: 'Tier',
    type: 'text', // Default type for non-numeric text values
    numeric: false,
  },
  {
    id: 'leaderboard_name',
    label: 'Leaderboard',
    type: 'link', // Column type for links
    numeric: false,
    properties: {
      page: 'Leaderboard/id/', // Base URL for the link
      link_data: 'leaderboard_id', // Data to append to the link
    },
  },
  {
    id: 'faceoff',
    label: 'Rank Now',
    type: 'faceoff', // Custom column type for faceoff actions
    numeric: false,
    properties: {
      criterion: 'criterion', // Field for criterion
      competitor: 'corelation_id', // Field for competitor ID
    },
  },
];


const CompetitorRankingTable = ({leaderboardId, competitorId, ranking, seeded,headCells=defaultHeadCells, defaultSortField="ranking", defaultSortOrder="asc"}) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async (competitorId,ranking,seeded,leaderboardId) => {
    try {
      let params = [];
      if (ranking) {
        params.push(`ranking=${ranking}`);
      }
      if (leaderboardId) {
        params.push(`leaderboard_id=${leaderboardId}`);
      }
      if(seeded != null) {
        params.push(`seeded=${seeded}`);
      }
      if (competitorId) {
        params.push(`competitor_id=${competitorId}`);       
      }
      let qryParams = params.join("&");
      const url = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboardRankings?${qryParams}`;
      console.log("URL ",  url);
      const response = await axios.get(url);
      setRows(response.data); // Assuming the API returns the array of rows directly
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  // Fetch data from the API
  useEffect(() => {
    fetchData(competitorId, ranking, seeded,leaderboardId);
  }, [competitorId, ranking, seeded, leaderboardId]);
  

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
      <FaceOffTableV2 rows={rows} headCells={headCells} defaultSortField={defaultSortField} defaultSortOrder={defaultSortOrder} />
  );
};

export default CompetitorRankingTable;
