import * as React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Chip } from '@mui/material';
import { CompareArrows } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Search } from '@mui/icons-material';
import SocialShare from './SocialShare';

// Define the mobile breakpoint as a constant
const MOBILE_BREAKPOINT = 800;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function FaceOffTableV2({ order, orderBy, headCells, onRequestSort }) {
  const isMobile = window.innerWidth < MOBILE_BREAKPOINT; // Use the constant here
  const visibleHeadCells = isMobile
    ? headCells.filter((headCell) => !headCell.hideMobile)
    : headCells;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {visibleHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: 'black',
              color: 'white',
              '& .MuiTableSortLabel-root': { color: 'white' },
              '& .MuiTableSortLabel-root.Mui-active': { color: 'white' },
              '& .MuiTableSortLabel-root:hover': { color: 'lightgray' },
              '& .MuiTableSortLabel-icon': { color: 'white !important' },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

FaceOffTableV2.propTypes = {
  headCells: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default function EnhancedTable({
  rows,
  headCells,
  defaultSortField = headCells[0]?.id,
  defaultSortOrder = 'asc',
  enableSearch = true,
}) {
  const [order, setOrder] = React.useState(defaultSortOrder);
  const [orderBy, setOrderBy] = React.useState(defaultSortField);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState('');
  const isMobile = window.innerWidth < MOBILE_BREAKPOINT; // Use the constant here

  const visibleHeadCells = isMobile
    ? headCells.filter((headCell) => !headCell.hideMobile)
    : headCells;

  const filteredRows = React.useMemo(() => {
    if (!searchTerm) return rows;
    return rows.filter((row) =>
      visibleHeadCells.some((headCell) => {
        const cellValue = row[headCell.id];
        return cellValue && cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  }, [rows, searchTerm, visibleHeadCells]);

  const sortedRows = React.useMemo(
    () => [...filteredRows].sort(getComparator(order, orderBy)),
    [filteredRows, order, orderBy]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const rowCount = filteredRows.length;
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - rowCount);
  const visibleRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const renderCellContent = (row, headCell) => {
    const prefixEmoji = headCell.properties?.defaultEmoji || '';

    if (headCell.hideMobile && window.innerWidth < MOBILE_BREAKPOINT) return null;

    switch (headCell.type) {
      case 'faceoff':
        return (
          <div>
            {prefixEmoji}
            <Chip
              icon={<CompareArrows />}
              label="Rank"
              clickable
              onClick={() =>
                window.location.assign(
                  `/faceoff?primary_opponent_id=${row[headCell.properties.competitor]}&criterion_name=${row[headCell.properties.criterion]}&mode=primary`
                )
              }
              sx={{
                backgroundColor: 'lightgray',
                '&:hover': { backgroundColor: 'darkgray' },
              }}
            />
          </div>
        );
      case 'tier': {
        const tierValue = row[headCell.id];
        const tierClass = `tier-${tierValue}`;
        return (
          <div className={`tier ${tierClass}`}>
            <div className="tier-header">
              {prefixEmoji} {tierValue}
            </div>
          </div>
        );
      }
      case 'boolean':
        return `${prefixEmoji}${row[headCell.id] ? 'Yes' : 'No'}`;
      case 'image':
        return row[headCell.id] ? (
          <div>
            {prefixEmoji}
            <img
              src={row[headCell.id]}
              alt={row[headCell.id]}
              style={{ width: 'auto', maxHeight: '50px', borderRadius: '8px' }}
            />
          </div>
        ) : (
          `${prefixEmoji}—`
        );
      case 'link':
        return (
          row[headCell.properties.link_data] && (
            <div>
              {prefixEmoji}
              <Link
                to={`/${headCell.properties.page_name}${row[headCell.properties.link_data]}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {row[headCell.id] || '—'}
              </Link>
            </div>
          )
        );
      case 'share': {
        const props = headCell.properties;
        return (
          <div>
            {prefixEmoji}
            <SocialShare
              primaryCompetitorId={row[props.primaryCompetitorId]}
              primaryCompetitorName={row[props.primaryCompetitorName]}
              primaryCompetitorImage={row[props.primaryCompetitorImage]}
              challengerCompetitorId={row[props.challengerCompetitorId]}
              challengerCompetitorName={row[props.challengerCompetitorName]}
              criterion={row[props.criterion]}
              displayName="Share"
            />
          </div>
        );
      }
      default:
        return row[headCell.id] !== undefined && row[headCell.id] !== null
          ? `${prefixEmoji}${row[headCell.id]}`
          : `${prefixEmoji}—`;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {enableSearch && (
        <TextField
          variant="outlined"
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
          sx={{ m: 2, width: '80%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
      <TableContainer>
        <Table sx={{ minWidth: 300 }} aria-labelledby="tableTitle">
          <FaceOffTableV2
            order={order}
            orderBy={orderBy}
            headCells={visibleHeadCells}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {visibleRows.length > 0 ? (
              visibleRows.map((row, index) => (
                <TableRow hover tabIndex={-1} key={index}>
                  {visibleHeadCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'}>
                      {renderCellContent(row, headCell)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={visibleHeadCells.length}>
                  <Typography align="center" variant="body1">
                    No results found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
