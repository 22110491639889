import {React, useState} from 'react';
import { useUser } from '../UserContext';
import {Container,  Card, Typography, Tabs, Tab, Box } from '@mui/material';
import ProfileCard from '../components/ProfileCard';
import FaceOffDataTable from '../components/FaceOffDataTable';
import Login from './Login';

const Profile = () => {

  const userLeaderboardCells = [
    {
      id: 'external_tiny_avatar_URL',
      label: 'Avatar',
      type: 'image', // Defines the column as an image type
      numeric: false,
      properties: {

      },
    },
    {
      id: 'display_name',
      label: 'Username',
      type: 'text', // Default column type
      numeric: false,
      properties: {
      },
    },
    {
      id: 'seededRate',
      label: '% Games Seeded',
      type: 'text', // Default column type for numeric values
      numeric: true,
      properties: {
      },
      hideMobile:true
    },
    {
      id: 'rankingCount',
      label: '# Games Compared',
      type: 'text', // Default column type for numeric values
      numeric: true,
      properties: {
      },
      hideMobile:true
    },
    {
      id: 'score',
      label: 'Total Score',
      type: 'text', // Default column type for numeric values
      numeric: true,
      properties: {
      },

    }
  ];

  const almostSeededCells = [
    {
      id: 'large_image_url',
      label: 'Image',
      type: 'image', // Column type for images
      numeric: false,
      properties: {
        isImage: true, // Indicates this column contains images
      },
    },
    {
      id: 'name',
      label: 'Game',
      type: 'link', // Column type for links
      numeric: false,
      properties: {
        page: 'competitor/', // Base URL for the link
        link_data: 'competitor_id', // Data to append to the link
      },
      hideMobile:true
    },
    {
      id: 'leaderboard_name',
      label: 'Leaderboard Name',
      type: 'link', // Column type for links
      numeric: false,
      properties: {
        page: 'Leaderboard/id/', // Base URL for the link
        link_data: 'leaderboard', // Data to append to the link,
        defaultEmoji: '🏆'
      },
    },
    {
      id: 'ranked_count',
      label: 'Ranked Count',
      type: 'text', // Default type for numeric/text values
      numeric: true,
      properties: {
      },
    },
    {
      id: 'faceoff',
      label: 'Rank Now',
      type: 'faceoff', // Custom column type for faceoff actions
      numeric: false,
      properties: {
        criterion: 'criterion', // Field for criterion
        competitor: 'competitor_id', // Field for competitor ID
      },
    },
  ];
  
  
    const { user } = useUser();
    const [activeTab, setActiveTab] = useState(0); // Default tab is 1 (FaceOffImageList)
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue); // Update active tab when user clicks on a tab
      };

    if (!user) {
        return (
            <Login/>
        );
    }

    return (
        <Container>
            <ProfileCard user={user}></ProfileCard>
            <Box sx={{ width: '100%', mt: 4 }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="Almost Seeded Games" />
            <Tab label="User Leaderboard" />
          </Tabs>

          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <Card sx={{ mt: 4, p: 2 }}>
                <Typography variant="h4" component="div">
                Almost Seeded Games
                </Typography>
                <Typography variant="body2" component="div">
                Games are only included on leaderboards once they have been ranked a number of times.
                </Typography>
                <FaceOffDataTable endpoint={`${process.env.REACT_APP_SERVER_URL}/GetMyUnseededCompetitors`} headCells={almostSeededCells} defaultSortField={'ranked_count'} defaultSortOrder={"desc"}/>
              </Card>
            )}
            {activeTab === 1 && (
              <Card sx={{ mt: 4, p: 2 }}> 
              <Typography variant="h4" component="div">
                User Leaderboard
                </Typography>
                <Typography variant="body2" component="div">
                Statistics for all users to compare. Try and beat your friends!
                </Typography>
                <FaceOffDataTable endpoint={`${process.env.REACT_APP_SERVER_URL}/getProfileLeaderboard`} headCells={userLeaderboardCells} defaultSortField={'score'} defaultSortOrder={"desc"}/>
              </Card>
            )}
          </Box>
        </Box>
                

        </Container>
    );
};

export default Profile;
